<template>
  <div class="containerView">
    <router-view />
  </div>
  <footer class="footer">
    <div class="router-footer">
      <router-link class="mr-[20px] hover:text-yellow-400" to="privacy-policy"
        >Privacy Policy</router-link
      >
      <router-link to="terms-of-service" class="hover:text-yellow-400">Terms of Service</router-link>
    </div>
    <div class="mb-[10px]">
      If you have any feedback or ideas for our website, please feel free to
      send an email to novabeatsapp@gmail.com
    </div>
    <p>© 2023 NovaBeats Video.</p>
  </footer>
</template>

<script setup></script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
}
.containerView {
  @apply md:w-[1080px] md:min-w-[1080px] md:m-auto;
}
.footer {
  @apply bg-gray-200 text-gray-700 text-[.32rem] py-2 text-center mt-2 md:text-[14px] md:py-[15px];
}
.router-footer {
  @apply mb-[10px];
}
</style>
