import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const urlList = [
  "media.nova-beats.com",
  "novabeatsapp.com",
  "nova-beats.net",
  "nova-beatsapp.com",
  "novabeatsvip.com",
];
const appUrl2 = window.location.href;
let firebaseConfig = {
  apiKey: "AIzaSyCO1BMFaAVBichokkh0RhkPZbhnGduSSvY",
  authDomain: "shortvideo-4bd83.firebaseapp.com",
  projectId: "shortvideo-4bd83",
  storageBucket: "shortvideo-4bd83.firebasestorage.app",
  messagingSenderId: "1090936506539",
  appId: "1:1090936506539:web:6ed279877a6d479515cfee",
  measurementId: "G-F7KDK0F2RY",
};
// eslint-disable-next-line no-undef
switch (getHrefUrl2(appUrl2)) {
  case "media.nova-beats.com":
    firebaseConfig.measurementId = "G-F7KDK0F2RY";
    break;
  case "novabeatsapp.com":
    firebaseConfig.measurementId = "G-67XCNVQD6Z";
    break;
  case "nova-beats.net":
    firebaseConfig.measurementId = "G-9J477ZBQQD";
    break;
  case "nova-beatsapp.com":
    firebaseConfig.measurementId = "G-SQDEWP49WK";
    break;
  case "novabeatsvip.com":
    firebaseConfig.measurementId = "G-T84FYRLBDD";
    break;
  default:
    firebaseConfig.measurementId = "G-F7KDK0F2RY";
    break;
}
function getHrefUrl2(url) {
  let urlData = "";
  urlList.forEach((item) => {
    if (url.indexOf(item) > -1) {
      urlData = item;
    }
  });
  return urlData;
}
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default {
  app,
  analytics,
};
