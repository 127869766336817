import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "../src/assets/main.css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import firebaseApp from "./assets/js/firebaseConfig";
import './assets/css/common.css';
const hls = require("videojs-contrib-hls");
const bool =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
const urlList = [
  "media.nova-beats.com",
  "novabeatsapp.com",
  "nova-beats.net",
  "nova-beatsapp.com",
  "novabeatsvip.com",
];
let appUrl = window.location.href; //`<%= VUE_APP_BASE_URL %>`; // 线上地址
let gtagID = "G-Q6BRJXQ3LV";
let adsClient = "8237884947473629";
let home_1_slot = "9090800263";
let home_2_slot = "2455469102";
let home_3_slot = "6751405947";
let home_4_slot = "6077452675";
let playlets_1_slot = "1142387432";
let playlets_2_slot = "7516224094";
let play_1_slot = "2138207669";
let play_2_slot = "2314473071";
switch (getHrefUrl(appUrl)) {
  case "media.nova-beats.com":
    gtagID = "G-Q6BRJXQ3LV";
    adsClient = "8237884947473629";
    home_1_slot = "9090800263";
    home_2_slot = "2455469102";
    home_3_slot = "6751405947";
    home_4_slot = "6077452675";
    playlets_1_slot = "1142387432";
    playlets_2_slot = "7516224094";
    play_1_slot = "2138207669";
    play_2_slot = "2314473071";
    break;
  case "novabeatsapp.com":
    gtagID = "G-N2TGCCXWJH";
    adsClient = "2696334033428745";
    home_1_slot = "5235144297";
    home_2_slot = "2369307807";
    home_3_slot = "5439329863";
    home_4_slot = "6011570732";
    playlets_1_slot = "7007390964";
    playlets_2_slot = "1330089693";
    play_1_slot = "7703926350";
    play_2_slot = "6356654279";
    break;
  case "nova-beats.net":
    gtagID = "G-B2FFMX2EG5";
    adsClient = "7480403109429724";
    home_1_slot = "3838473585";
    home_2_slot = "2068456294";
    home_3_slot = "2525391918";
    home_4_slot = "4047752376";
    playlets_1_slot = "7286592563";
    playlets_2_slot = "7129211282";
    play_1_slot = "4503047945";
    play_2_slot = "7745757568";
    break;
  case "nova-beatsapp.com":
    gtagID = "G-C81RVZGV1Y";
    adsClient = "2696334033428745";
    home_1_slot = "2920143583";
    home_2_slot = "2369307807";
    home_3_slot = "3970335526";
    home_4_slot = "6427309603";
    playlets_1_slot = "7718008845";
    playlets_2_slot = "5091845502";
    play_1_slot = "1056226130";
    play_2_slot = "2465682163";
    break;
  case "novabeatsvip.com":
    gtagID = "G-TM6R7BQJYN";
    adsClient = "2696334033428745";
    home_1_slot = "5618287679";
    home_2_slot = "7211576536";
    home_3_slot = "9400151401";
    home_4_slot = "9263024803";
    playlets_1_slot = "5629895968";
    playlets_2_slot = "4316814298";
    play_1_slot = "4151694157";
    play_2_slot = "2838612486";
    break;
}
function getHrefUrl(url) {
  let urlData = "";
  urlList.forEach((item) => {
    if (url.indexOf(item) > -1) {
      urlData = item;
    }
  });
  return urlData;
}

let adsbygoogleJs = document.createElement("script");
adsbygoogleJs.async = true;
adsbygoogleJs.type = "text/javascript";
adsbygoogleJs.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-${adsClient}`;
document.getElementsByTagName("head")[0].appendChild(adsbygoogleJs);

let ima3Script = document.createElement("script");
ima3Script.async = true;
ima3Script.type = "text/javascript";
ima3Script.src = "https://imasdk.googleapis.com/js/sdkloader/ima3.js";
document.getElementsByTagName("head")[0].appendChild(ima3Script);

let gptScript = document.createElement("script");
gptScript.async = true;
gptScript.crossorigin = "anonymous";
gptScript.type = "text/javascript";
gptScript.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
document.getElementsByTagName("head")[0].appendChild(gptScript);

let gtagScript = document.createElement("script");
gtagScript.async = true;
gtagScript.type = "text/javascript";
gtagScript.src = "https://www.googletagmanager.com/gtag/js?id=" + gtagID;
document.getElementsByTagName("head")[0].appendChild(gtagScript);

window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag("js", new Date());

gtag("config", gtagID);
const app = createApp(App);
app.provide("bool", bool);
app.provide("home_1_slot", home_1_slot);
app.provide("home_2_slot", home_2_slot);
app.provide("home_3_slot", home_3_slot);
app.provide("home_4_slot", home_4_slot);
app.provide("playlets_1_slot", playlets_1_slot);
app.provide("playlets_2_slot", playlets_2_slot);
app.provide("play_1_slot", play_1_slot);
app.provide("play_2_slot", play_2_slot);
app.provide("adsClient", adsClient);
app.provide("gtagID", gtagID);
app.config.globalProperties.$bool = bool;
app.use(router).use(ElementPlus).use(hls).use(firebaseApp).mount("#app");
